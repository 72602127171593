import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const Bold = styled('strong', {
  fontWeight: 'medium',
});

export type BoldProps = ComponentProps<typeof Bold> & {
  css?: CSS;
};
