import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const UnorderedList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 24,
  gap: 16,
});

export type UnorderedListProps = ComponentProps<typeof UnorderedList> & {
  css?: CSS;
};
