import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const Italic = styled('em', {
  fontStyle: 'italic',
});

export type ItalicProps = ComponentProps<typeof Italic> & {
  css?: CSS;
};
