/**
 * These are routes that are shared with app. Make sure to keep this up to date with app.
 */
export const SharedRoutes = {
  appStore: 'https://itunes.apple.com/app/apple-store/id1244558410?pt=118626354&ct=aircamwebsite&mt=8',
  helpCenter: 'https://help.air.inc',
  playStore: 'https://play.google.com/store/apps/details?id=com.airapp.android',
  releases: 'https://www.notion.so/aircamera/What-s-New-1d5da1c7779a4e71a94971834d5cdf0e',
  status: 'https://status.air.inc/',
};

/**
 * These are routes that start with `mailto:`
 */
export const MailToRoutes = {
  hello: 'mailto:hello@air.inc',
  prr: 'mailto:pr@air.inc',
};

export const AppRoutes = {
  library: '/library',
};

/**
 * These are social media routes.
 */
export const SocialMediaRoutes = {
  github: 'https://github.com/AirLabsTeam',
  instagram: 'https://instagram.com/air.hq',
  linkedIn: 'https://www.linkedin.com/company/airhq/',
  tiktok: 'https://www.tiktok.com/@meetair',
  twitter: 'https://twitter.com/airHQ',
  youtube: 'https://www.youtube.com/channel/UCUgwosdkOa7oWs5wacfSTWQ',
};

export const AuthRoutes = {
  signUp: '/sign-up',
  login: '/login',
};

export const Routes = {
  airFlow: 'https://air.inc/air-flow',
  airShop: 'https://shop.air.inc/',
  about: 'https://air.inc/about',
  adobePremierePro: 'https://air.inc/adobe-premiere-pro',
  adobePremiereProDownload: 'https://partners.adobe.com/exchangeprogram/creativecloud/exchange.details.107604.html',
  agencies: 'https://air.inc/agencies',
  betaProgram: 'https://air.inc/beta-program',
  blog: 'https://air.inc/blog',
  careers: 'https://apply.workable.com/air/',
  contentCleanersDigitalLibraryOrganizers: 'https://air.inc/content-cleaners-digital-library-organizers',
  creatorProgram: 'https://air.inc/creator-program',
  comparison: 'https://air.inc/comparison',
  comparisonBrandExperience: 'https://air.inc/comparison/brand-experience',
  comparisonCloudStorageAlternative: 'https://air.inc/comparison/cloud-storage-alternative',
  comparisonDigitalAssetManagement: 'https://air.inc/comparison/digital-asset-management',
  comparisonDropboxAlternative: 'https://air.inc/comparison/dropbox-alternative',
  comparisonGoogleDriveAlternative: 'https://air.inc/comparison/google-drive-alternative',
  comparisonBox: 'https://air.inc/comparison/box-alternative',
  comparisonDAM: 'https://air.inc/comparison/digital-asset-management',
  culture: 'https://air.inc/culture',
  cultureManagement: 'https://air.inc/culture/management',
  culturePolicies: 'https://air.inc/culture/policies',
  customerStories: 'https://air.inc/customers',
  demo: 'https://calendly.com/john_air/meetjohn',
  departmentDesignCreative: 'https://air.inc/department/design-creative',
  departmentMarketing: 'https://air.inc/department/marketing',
  departmentGrowth: 'https://air.inc/department/growth',
  desktop: 'https://air.inc/air-lift',
  diversity: 'https://air.inc/culture/diversity',
  download: 'https://air.inc/download',
  enterprise: 'https://air.inc/enterprise',
  features: 'https://air.inc/features',
  featuresDynamicFeedback: 'https://air.inc/features/dynamic-feedback',
  featuresFlexibleOrganization: 'https://air.inc/features/flexible-organization',
  featuresKanban: 'https://air.inc/features/kanban',
  featuresSmartSearch: 'https://air.inc/features/smart-search',
  featuresVersions: 'https://air.inc/features/versions',
  featuresVisualWorkspace: 'https://air.inc/features/visual-workspace',
  flexibleOrganization: 'https://air.inc/features/flexible-organization',
  inclusion: 'https://air.inc/culture/inclusion',
  industry: 'https://air.inc/industry',
  industryAgencies: 'https://air.inc/industry/agencies',
  industryAthletics: 'https://air.inc/industry/athletics',
  industryEducation: 'https://air.inc/industry/education',
  industryMedia: 'https://air.inc/industry/media',
  industryNonprofits: 'https://air.inc/industry/nonprofits',
  industryRetail: 'https://air.inc/industry/retail',
  integrations: 'https://air.inc/integrations',
  iphone: 'https://apps.apple.com/app/apple-store/id1244558410',
  liveEvents: 'https://air.inc/live-events',
  logos: 'https://air.inc/logos',
  migrate: 'https://air.inc/switch',
  operators: 'https://air.inc/operators',
  partnerProgram: 'https://air.inc/partner-program',
  pricing: 'https://air.inc/pricing',
  privacy: 'https://air.inc/legal/privacy',
  releaseNotes: 'https://www.notion.so/airinc/What-s-New-on-Air-1d5da1c7779a4e71a94971834d5cdf0e',
  resources: 'https://air.inc/resources',
  root: 'https://air.inc/',
  security: 'https://air.inc/security',
  store: 'https://shop.air.inc',
  terms: 'https://air.inc/legal/terms',
  tour: 'https://air.inc/tour',
  tourCreativeApprovals: 'https://air.inc/tour/creative-approvals',
  tourCreativeRequests: 'https://air.inc/tour/creative-requests',
  tourOnboardFreelancers: 'https://air.inc/tour/onboard-freelancers',
  tourRightsManagement: 'https://air.inc/tour/rights-management',
  visualWorkspace: 'https://air.inc/features/visual-workspace',
  worthlessStudios: 'https://air.inc/blog/worthless-studios',

  /**
   * Landing page routes. Subject to change
   */
  semlp1forma: 'https://air.inc/landing/sem-lp-form-1',
  semlp1formb: 'https://air.inc/landing/sem-lp-form-2',
  semlp1buttona: 'https://air.inc/landing/sem-lp-button-1',
  semlp1buttonb: 'https://air.inc/landing/sem-lp-button-2',
};

export const DynamicRoutes = {
  _sandbox: '/sandbox/[slug]',
  agencies: '/agencies/[slug]',
  comparison: '/comparison/[slug]',
  department: '/department/[slug]',
  features: '/features/[slug]',
  industry: '/industry/[slug]',
  landing: '/landing/[slug]',
  legal: '/legal/[slug]',
  logos: '/logos',
  resources: '/resources/[slug]',
  tour: '/tour/[slug]',
};

export const BlogRoutes = {
  archive: '/blog/archive',
  categories: '/blog/category',
  home: '/blog',

  /**
   * These are routes that contains a custom page renderer.
   */
  firstagram: '/blog/1stagram',
  nycRooftopBarsRestaurants: '/blog/nyc-rooftop-bars-restaurants',
  nycCreativeCouncil: '/blog/nyc-creative-council',
  sixFeetCalculator: '/blog/6feetcalculator',
  supportNYC: '/blog/supportnycdtc',
  creativeOps: '/blog/category/creative-ops',
  buildingBrands: '/blog/category/building-brands',
  airNews: '/blog/category/air-news',
};

export const BlogDynamicRoutes = {
  category: '/blog/category/[slug]',
  post: '/blog/[slug]',
};
