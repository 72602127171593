import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const OrderedList = styled('ol', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  paddingLeft: 24,
});

export type OrderedListProps = ComponentProps<typeof OrderedList> & {
  css?: CSS;
};
