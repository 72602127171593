import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const Divider = styled('hr', {
  height: 1,
  border: 0,
  borderBottom: '1px solid',
  borderColor: '$pigeon025',
});

export type DividerProps = ComponentProps<typeof Divider> & {
  css?: CSS;
};
