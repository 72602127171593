import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const Underline = styled('u', {
  textDecoration: 'underline',
});

export type UnderlineProps = ComponentProps<typeof Underline> & {
  css?: CSS;
};
