import { forwardRef } from 'react';

import { Text, TextProps } from './Text';

export type ParagraphProps = Omit<TextProps, 'as'>;

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(({ children, ...restOfProps }, ref) => {
  return (
    <Text as="p" {...restOfProps} ref={ref}>
      {children}
    </Text>
  );
});

Paragraph.displayName = 'Paragraph';
