import { ComponentProps, ElementRef, forwardRef, ReactNode } from 'react';

import { Box } from './Box';
import { CSS, keyframes, styled } from './stitches.config';

const StyledRoot = styled('button', {
  appearance: 'none',
  outline: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$button$test',
  border: 0,
  borderRadius: 4,
  margin: 0,
  boxSizing: 'border-box',
  fontFamily: '$copy',
  fontWeight: '$semibold',
  lineHeight: 1,
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease, color 0.2s ease',

  '&:hover': {
    textDecoration: 'none',
  },

  '&:focus-visible': {
    boxShadow: '0 0 0 2px $colors$focus',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '&[data-loading]': {
    cursor: 'progress',
  },

  variants: {
    appearance: {
      filled: {
        '&:disabled:not([data-loading])': {
          backgroundColor: '$pigeon025',
          color: '$pigeon300',
        },
      },
      ghost: {
        backgroundColor: 'transparent',

        '&:disabled:not([data-loading])': {
          backgroundColor: 'transparent',
          color: '$pigeon300',
        },
      },
      outline: {
        backgroundColor: 'transparent',
        border: '1px solid $colors$pigeon200',

        '&:disabled:not([data-loading])': {
          backgroundColor: 'transparent',
          border: '1px solid $colors$pigeon200',
          color: '$pigeon500',
        },
      },
    },

    color: {
      ['jay500']: {},
      ['macaw350']: {},
      ['macaw060']: {},
      ['pigeon050']: {},
      destructive: {},
    },

    size: {
      ['extra-small']: {
        height: 24,
        paddingX: 8,
        fontSize: 12,
      },
      small: {
        height: 32,
        paddingX: 10,
        fontSize: 14,
      },
      medium: {
        height: 40,
        paddingX: 12,
        fontSize: 16,
      },
      large: {
        height: 48,
        paddingX: 24,
        fontSize: 16,
      },
    },
  },

  compoundVariants: [
    {
      appearance: 'filled',
      color: 'jay500',
      css: {
        backgroundColor: '$jay500 !important',
        border: 0,
        color: '$white',

        '&:hover:not([data-loading])': {
          backgroundColor: '$jay600',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$jay700',
        },
      },
    },
    {
      appearance: 'filled',
      color: 'macaw350',
      css: {
        backgroundColor: '$macaw350',
        border: 0,
        color: '$white',

        '&:hover:not([data-loading])': {
          backgroundColor: '$macaw400',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$macaw500',
        },
      },
    },
    {
      appearance: 'filled',
      color: 'macaw060',
      css: {
        backgroundColor: '$macaw060',
        border: 0,
        color: '$macaw600',

        '&:hover:not([data-loading])': {
          backgroundColor: '$macaw080',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$macaw100',
        },
      },
    },
    {
      appearance: 'filled',
      color: 'pigeon050',
      css: {
        backgroundColor: '$pigeon050',
        color: '$pigeon600',

        '&:hover:not([data-loading])': {
          backgroundColor: '$pigeon100',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$pigeon200',
          color: '$pigeon700',
        },
      },
    },
    {
      appearance: 'filled',
      color: 'destructive',
      css: {
        backgroundColor: '$flamingo600',
        color: '$white',

        '&:hover:not([data-loading])': {
          backgroundColor: '$flamingo700',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$flamingo800',
        },
      },
    },
    {
      appearance: 'ghost',
      color: 'jay500',
      css: {
        color: '$jay600',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(46, 119, 255, 0.15)', // jay300 @ 15%
          color: '$jay600',
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(46, 119, 255, 0.3', // jay300 @ 30%
          color: '$jay600',
        },
      },
    },
    {
      appearance: 'ghost',
      color: 'pigeon050',
      css: {
        color: '$pigeon600',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(38, 38, 38, 0.1)', // pigeon800 @ 10%
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(38, 38, 38, 0.15)', // pigeon800 @ 15%
        },
      },
    },
    {
      appearance: 'ghost',
      color: 'destructive',
      css: {
        color: '$flamingo700',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(255, 105, 105, 0.15)', // flamingo300 @ 15%
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(255, 105, 105, 0.25)', // flamingo300 @ 15%
        },
      },
    },
    {
      appearance: 'outline',
      color: '$jay500',
      css: {
        borderColor: '$jay600',
        color: '$jay600',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(46, 119, 255, 0.15)', // jay300 @ 15%
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(46, 119, 255, 0.3)', // jay300 @ 30%
        },
      },
    },
    {
      appearance: 'outline',
      color: 'macaw350',
      css: {
        color: '$macaw350',

        '&:hover:not([data-loading])': {
          backgroundColor: '$macaw060',
          color: '$macaw500',
        },

        '&:active:not([data-loading])': {
          backgroundColor: '$macaw080',
          color: '$macaw600',
        },
      },
    },
    {
      appearance: 'outline',
      color: 'pigeon050',
      css: {
        borderColor: '$pigeon500',
        color: '$pigeon600',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(38, 38, 38, 0.1)', // pigeon050 @ 10%
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(38, 38, 38, 0.15)', // pigeon050 @ 15%
        },
      },
    },
    {
      appearance: 'outline',
      color: 'destructive',
      css: {
        borderColor: '$flamingo700',
        color: '$flamingo700',

        '&:hover:not([data-loading])': {
          backgroundColor: 'rgba(255, 105, 105, 0.15)', // flamingo300 @ 15%
        },

        '&:active:not([data-loading])': {
          backgroundColor: 'rgba(255, 105, 105, 0.25)', // flamingo300 @ 25%
        },
      },
    },
  ],

  defaultVariants: {
    appearance: 'filled',
    color: 'jay500',
    size: 'medium',
  },
});

export const StyledAdornment = styled('div', {
  variants: {
    side: {
      left: {
        marginRight: 4,
        marginLeft: -4,
      },
      right: {
        marginRight: -4,
        marginLeft: 4,
      },
    },
    size: {
      ['extra-small']: {},
      small: {},
      medium: {},
      large: {},
    },
  },

  compoundVariants: [
    {
      side: 'left',
      size: 'extra-small',
      css: {
        marginLeft: -2,
      },
    },
    {
      side: 'right',
      size: 'extra-small',
      css: {
        marginRight: -2,
      },
    },
  ],
});

const bounceKeyframe = keyframes({
  '0%': { transform: 'translate(0, -1.5px)' },
  '50%': { transform: 'translate(0, 1.5px)' },
  '100%': { transform: 'translate(0, -1.5px)' },
});

const StyledLoaderDot = styled('div', {
  backgroundColor: 'currentColor',
  width: 4,
  height: 4,
  borderRadius: 4,
  animation: `${bounceKeyframe} 980ms ease-in-out`,
});

const StyledLoader = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  transform: 'translate(-50%, -50%)',

  [`& ${StyledLoaderDot}`]: {
    animation: `${bounceKeyframe} 980ms ease-in-out 0s both infinite`,

    '&:nth-of-type(1)': {
      animationDelay: '0s',
    },

    '&:nth-of-type(2)': {
      marginX: 4,
      animationDelay: '0.14s',
    },

    '&:nth-of-type(3)': {
      animationDelay: '0.28s',
    },

    '@reducedMotion': {
      animationName: 'none',
    },
  },
});

export type ButtonProps = ComponentProps<typeof StyledRoot> & {
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  css?: CSS;
  isLoading?: boolean;
  as?: keyof JSX.IntrinsicElements;
};

export const Button = forwardRef<ElementRef<typeof StyledRoot>, ButtonProps>(
  (
    {
      adornmentLeft,
      adornmentRight,
      appearance = 'filled',
      children,
      className,
      color = 'jay500',
      css,
      disabled = false,
      isLoading = false,
      onClick,
      size = 'medium',
      type = 'button',
      ...restOfProps
    }: ButtonProps,
    forwardedRef,
  ) => {
    const content = (
      <>
        {adornmentLeft && (
          <StyledAdornment side="left" size={size}>
            {adornmentLeft}
          </StyledAdornment>
        )}
        {children}
        {adornmentRight && (
          <StyledAdornment side="right" size={size}>
            {adornmentRight}
          </StyledAdornment>
        )}
      </>
    );
    return (
      <StyledRoot
        appearance={appearance}
        className={className}
        color={color}
        css={css}
        disabled={disabled}
        data-loading={isLoading ? '' : undefined}
        onClick={onClick}
        ref={forwardedRef}
        size={size}
        type={type}
        {...restOfProps}
      >
        {isLoading ? (
          <Box css={{ position: 'relative' }} role="status">
            <StyledLoader aria-hidden="true">
              <StyledLoaderDot />
              <StyledLoaderDot />
              <StyledLoaderDot />
            </StyledLoader>

            <Box aria-hidden="true" css={{ opacity: 0, visibility: 'hidden' }}>
              {content}
            </Box>
          </Box>
        ) : (
          content
        )}
      </StyledRoot>
    );
  },
);

Button.displayName = 'Button';
