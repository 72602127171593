import { ComponentProps, ElementRef, forwardRef } from 'react';

import { CSS } from './stitches.config';
import { Text, TextProps } from './Text';

export const HEADING_ELEMENT_TAG = 'h1';

export type HeadingProps = ComponentProps<typeof HEADING_ELEMENT_TAG> &
  Pick<TextProps, 'size' | 'transform' | 'weight'> & {
    css?: CSS;
    hierarchy: 1 | 2 | 3 | 4 | 5 | 6;
  };

export const Heading = forwardRef<ElementRef<typeof HEADING_ELEMENT_TAG>, HeadingProps>(
  ({ children, hierarchy = 1, weight = 'semibold', ...restOfProps }, forwardedRef) => {
    const elementTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = `h${hierarchy}`;

    return (
      <Text as={elementTag} weight={weight} {...restOfProps} ref={forwardedRef}>
        {children}
      </Text>
    );
  },
);

Heading.displayName = 'Heading';
