import { ComponentProps } from 'react';

import { CSS, styled } from './stitches.config';

export const Anchor = styled('a', {
  color: '$jay500',
  fontFamily: '$copy',
  fontWeight: 'semibold',
  wordBreak: 'break-word',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'color 0.2s ease, text-decoration 0.2s ease',

  '&:focus, &:hover': {
    textDecoration: 'underline',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:focus-visible': {
    boxShadow: '0 0 0 2px $colors$macaw100',
  },
});

export type AnchorProps = ComponentProps<typeof Anchor> & {
  css?: CSS;
};
