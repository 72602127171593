import { styled } from './stitches.config';

export const Input = styled('input', {
  outline: 0,
  width: '100%',
  border: '1px solid $colors$pigeon200',
  borderRadius: 4,
  color: '$pigeon700',
  fontFamily: '$copy',
  letterSpacing: '-0.015em',
  lineHeight: 1.5,

  '&:disabled': {
    opacity: 0.75,
    cursor: 'not-allowed',
  },

  '&:focus': {
    borderColor: '$macaw200',
  },

  variants: {
    size: {
      ['extra-small']: {
        height: 24,
        paddingX: 8,
        fontSize: 12,
      },
      small: {
        height: 32,
        paddingX: 10,
        fontSize: 14,
      },
      medium: {
        height: 40,
        paddingX: 12,
        fontSize: 16,
      },
      large: {
        height: 48,
        paddingX: 24,
        fontSize: 16,
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});
